#projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.project {
  /* min-height: max(100vh, 720px); */
  z-index: 1;
  min-height: 400px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.project:first-child {
  margin-top: 70vh;
}

.project-image-frame {
  position: relative;
  /* 
  width: 90%;
  height: clamp(360px, 540px, 540px); 
  */
  width: 100%;
  height: 100%;
  border: 0.5rem solid var(--primary-border);
  border-radius: 1rem;
  overflow: hidden;
  transition: border 0.2s ease-out;
}

.project-image-frame:hover {
  border: 0 solid var(--primary-border);
}

.project-image {
  /* 
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  display: inline;
  */
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.project-header {
  font-size: var(--step-1);
}

.project-description {
  font-size: var(--step--2);
  padding: 0.5rem 0 0.5rem;
}

.project-info {
  align-self: flex-start;
  background-color: rgba(var(--tertiary-bg), 0.9);
  padding: 1.5rem 1rem 1rem 1rem;
  margin-top: 5rem;
  margin-left: -7rem;
  border-radius: 1rem;
  max-width: 50%;
}

.project-links {
  display: flex;
  flex-direction: column;
}

.project-links a {
  margin-bottom: 0.5rem;
  font-size: var(--step--1);
  color: var(--secondary-txt);
  width: min-content;
}

.project-links a:hover {
  color: white;
}

@media only screen and (min-width: 768px) {
  #projects {
    padding: 3rem;
  }

  .project {
    min-height: max(100vh, 720px);
  }

  .project-image-frame {
    width: 960px;
    border: 1rem solid var(--primary-border);

    /* width: 90%; */
    /* height: clamp(360px, 540px, 540px); */
  }

  /* 
  .project-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    display: inline;
    width: 100%;
    height: 100%;
  } 
  */

  .project-info {
    max-width: 640px;
  }
}
