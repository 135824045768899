#welcome {
  height: 150vh;
}

.section-banner {
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 50%;
  width: fit-content;
  text-align: center;
  font-size: var(--step-0);
  background-color: rgba(var(--secondary-bg), 0.7);
  border-radius: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  z-index: 2;
}

#skills {
  height: 150vh;
}
