@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500&display=swap');

:root {
  /* Background Colors */
  --primary-bg: #242423;
  --secondary-bg: 38, 103, 255;
  --tertiary-bg: 0, 0, 0;

  /* Border Colors */
  --primary-border: #3b28cc;

  /* Text Colors */
  --primary-txt: #f8f9fa;
  --secondary-txt: #3f8efc;

  /* Responsive Font Sizes from Utopia.fyi */
  --step--2: clamp(0.96rem, calc(0.90rem + 0.05vw), 0.91rem);
  --step--1: clamp(1.20rem, calc(1.07rem + 0.11vw), 1.09rem);
  --step-0: clamp(1.31rem, calc(1.28rem + 0.19vw), 1.50rem);
  --step-1: clamp(1.58rem, calc(1.52rem + 0.30vw), 1.88rem);
  --step-2: clamp(1.89rem, calc(1.80rem + 0.45vw), 2.34rem);
  --step-3: clamp(2.27rem, calc(2.14rem + 0.66vw), 2.93rem);
  --step-4: clamp(2.72rem, calc(2.53rem + 0.94vw), 3.66rem);
  --step-5: clamp(3.27rem, calc(3.00rem + 1.31vw), 4.58rem);
}

html {
  background-color: var(--primary-bg);
  color: var(--primary-txt);
  font-family: 'Dosis', sans-serif;
}

* {
  box-sizing: border-box;
}

#canvas-container {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}

/* Scrollbars */

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: grey;
}

/* Chrome, Edge, Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 6px solid grey;
}

#ReactSimpleImageViewer {
  z-index: 9999;
}
